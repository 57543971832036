import React, { useState } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Search } from "react-feather"

const SearchForm = ({ query, setQuery }) => {
  const [currentQuery, setCurrentQuery] = useState(query)
  const handleSubmit = event => {
    event.preventDefault()
    setQuery(currentQuery)
  }

  return (
    <Wrapper>
      <form method="post" name="search">
        <input
          onChange={e => setCurrentQuery(e.target.value)}
          type="text"
          name="query"
          id="query"
          placeholder="Cerca autore o titolo o ISBN"
          value={currentQuery}
          required
        />
        <button onClick={handleSubmit}>
          <Search />
        </button>
      </form>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  form {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    /* width: 100%; */
  }
  input[type="text"] {
    font-family: "Source Code Pro";
    padding: 0.5rem 1rem;
    border: 2px solid ${DesignTokens.colors.primary[500]};
    border-right: none;
    text-transform: uppercase;
    font-weight: 700;
    color: ${DesignTokens.colors.primary[500]};
    &:focus {
      border-width: 2px;
    }
    width: 95%;
  }
  button {
    border: 2px solid ${DesignTokens.colors.primary[500]};
    border-left: none;
    background-color: transparent;
    color: ${DesignTokens.colors.primary[500]};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1rem;
    width: 5%;
    cursor: pointer;
  }
`

export default SearchForm
