import styled from 'styled-components'

const PageTitle = styled.div`
  padding-top: 3rem;
  padding-bottom: 4rem;
  text-align: center;
  & > * {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default PageTitle