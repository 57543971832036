import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-grid-system"
import BookThumb from "../components/bookThumb"
import { Display } from "../components/typography"
import PageTitle from "../components/pageTitle"
import InnerSearchForm from "../components/innerSearchForm"
import RichContentTextBlock from "../components/richContentTextBlock"
import { Box } from "theme-ui"

const CollectionPage = ({ data: { collection, books } }) => {
  return (
    <Layout>
      <SEO title={collection.name} />
      <Box sx={{ mt: [3, 4, 4] }}>
        <InnerSearchForm />
      </Box>
      <Wrapper>
        <Container>
          <Row>
            <Col>
              <PageTitle>
                <Display>{collection.name}</Display>
              </PageTitle>
              <Description>
                <RichContentTextBlock html={collection.text} />
              </Description>
            </Col>
          </Row>
          <Row gutterWidth={64}>
            <Col md={12}>
              <Row>
                {collection.books.map(book => (
                  <Col xs={6} sm={4} lg={3} key={book.originalId}>
                    <BookThumb book={book} />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

const Description = styled.div`
  margin-bottom: 4rem;
`

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default CollectionPage

export const query = graphql`
  query CollectionQuery($originalId: String!) {
    collection: datoCmsCollection(originalId: { eq: $originalId }) {
      name
      text
      slug
      originalId
      books {
        title
        slug
        originalId
        cover {
          fluid(maxWidth: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
        catalogue {
          name
          originalId
        }
        authors {
          name
        }
        digitalDownload
        newEdition {
          title
          slug
          originalId
        }
        subject {
          name
          slug
          originalId
          area {
            name
          }
        }
        price
      }
    }
    books: allDatoCmsBook(
      sort: { fields: [publishedAt, title], order: [DESC, ASC] }
      filter: { collection: { elemMatch: { originalId: { eq: $originalId } } } }
    ) {
      nodes {
        title
        slug
        originalId
        cover {
          fluid(maxWidth: 400) {
            ...GatsbyDatoCmsFluid
          }
        }
        catalogue {
          name
          originalId
        }
        authors {
          name
        }
        subject {
          name
          slug
          originalId
          area {
            name
          }
        }
        price
      }
    }
  }
`
