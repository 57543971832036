import React, { useEffect, useState } from "react"
import SearchForm from "../components/searchForm"
import { navigate } from "gatsby"
import { Col, Container, Row } from "react-grid-system"

const InnerSearchForm = () => {
  const [query, setQuery] = useState("")
  useEffect(() => {
    if (query) {
      navigate("/ricerca/", { state: { query: query } })
    }
  }, [query])
  return (
    <Container>
      <Row>
        <Col>
          <SearchForm query={query} setQuery={setQuery} />
        </Col>
      </Row>
    </Container>
  )
}

export default InnerSearchForm
